export const items = [
  {
    id: "1",
    topic: "Clase grabada para siempre que la quieras ver.",
  },
  {
    id: "2",
    topic: "7 modulos de aprendizaje.",
  },
  {
    id: "3",
    topic: "Documento con contenido exclusivo.",
  },
  {
    id: "4",
    topic: "Modelo de contrato personal.",
  },
  {
    id: "5",
    topic: "Certificado de participación completa.",
  },
  {
    id: "6",
    topic: "30% off en próximos cursos y membresías",
  },
  {
    id: "7",
    topic: "Guías de meditación.",
  },
];
