export const Clase1 = [
  {
    id: "1",
    item: "Identidad de marca.",
  },
  {
    id: "2",
    item:
      "Planificación estratégica y energética para crear contenido de calidad.",
  },
  {
    id: "3",
    item: "Cómo utilizar tu equipo y tus recursos.",
  },
  {
    id: "4",
    item: "Apps y herramientas para crear.",
  },
  {
    id: "5",
    item: "Como crear tu comunidad.",
  },
  {
    id: "6",
    item: "Trabajo directo con marcas, clientes 1:1",
  },
  {
    id: "7",
    item: "Ciclo de aprendizaje.",
  },
];
