export const footerItems = [
  {
    id: "1",
    item: "-Trabajar desde tu consciencia",
  },
  {
    id: "2",
    item: "-Crear tu metodología de ventas.",
  },
  {
    id: "3",
    item: "-Entender tu proceso.",
  },
  {
    id: "4",
    item:
      "-Crear un ecosistema de trabajo funcional y sostenible en el tiempo.",
  },
  {
    id: "5",
    item: "-Generar un plan rentable.",
  },
  {
    id: "6",
    item: "-Hacer crecer tus ingresos.",
  },
  {
    id: "7",
    item: "-Realizar inversiones inteligentes.",
  },
  {
    id: "8",
    item: "-Atraer clientes.",
  },
  {
    id: "9",
    item: "-Obtener claridad empresarial.",
  },
  {
    id: "10",
    item: "-Crecer personal y profesionalmente.",
  },
];
